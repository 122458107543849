<template>
            <Card class="card-custom _height">
                <template #title>
                    <v-tabs
          v-model="selectedTab"
          class="main-tabs"
          background-color="var(--white)"
      >
        <v-tab :to="toGenaralRoute">
          {{ $i18n.t('local_general') }}
        </v-tab>
        <v-tab v-if="!isAgent" :to="toLegalRoute">
          {{ $i18n.t('local_legal') }}
        </v-tab>
      </v-tabs>
                </template>
                <template #content>
                    <router-view>
                    </router-view>
                    <!-- <TabView @tab-change="handleTabChange">
                        <TabPanel :header="$t('local_general')">
                            <general-localization v-if="showLocalization" />
                        </TabPanel>
                        <TabPanel :header="$t('local_legal')">
                            <legal-localization v-if="showLegalLocalization" />
                        </TabPanel>
                    </TabView> -->
                </template>
            </Card>
</template>

<script>
import GeneralLocalization from './GeneralLocalization.vue'
import LegalLocalization from './LegalLocalization.vue'
    export default {
        name: 'Localizations',
        components:{
            GeneralLocalization,
            LegalLocalization,
        },
        beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'local')
    });
    },
        data(){
            return{
                showLocalization: true,
                showLegalLocalization: false,
                selectedTab: 0,
            }
        },
        computed: {
            isAgent() {
                return this.$route.path.includes('agent');
            },
            toLegalRoute() {
                if (this.$route.path.includes('admin')) {
                    
                    return '/localization/admin/legal'
                } else{
                    return '/localization/agent/legal'
                    
                }
            },
            toGenaralRoute() {
                if (this.$route.path.includes('agent')) {
                    
                    return '/localization/agent/general'
                } else{
                    return '/localization/admin/general'
                    
                }
            },
        },
        mounted() {
        },
        methods: {
            handleTabChange(event) {
                if (event.index === 0) {
                    this.showLegalLocalization = false;
                    this.showLocalization = true;
                } else if (event.index === 1) {
                    this.showLocalization = false;
                    this.showLegalLocalization = true;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>