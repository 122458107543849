<template>
          <div class="local">
            <div class="local__list list-local">
              <div class="d-flex align-center my-1">
                <div class="list-local__title list-title">
                  {{ $t('languages') }}
                </div>
                <v-spacer></v-spacer>
                <span class="p-buttonset">
                  <Button @click="resetOrders" :loading="loadingFlag" :disabled="!hasDraggbleChanges" :label="$t('reset')" icon="pi pi-refresh" class="p-button-sm p-button-danger" />
                  <Button @click="updateOrders" :loading="loadingFlag" :disabled="!hasDraggbleChanges" :label="$t('save_layout')" icon="pi pi-save" class="p-button-sm p-button-success" />
                </span>
                
              </div class="">
              <ul class="list-local__items">
                <input type="file" ref="uploadFlagForLocal" accept="image/png, image/jpeg" style="display: none" id="upload-logo-for-local"
                @change="uploadFlagForLocal($event)">
                <drop-list
                :items="draggableLocal"
                @reorder="$event.apply(draggableLocal)"
                >
                <template v-slot:item="{ item }">
                  <drag handle=".list-local-item__draggble" 
                  @dragend="hasDraggbleChanges = true"
                @click="selectLang(item)"
                class="list-local__item list-local-item" :class="{'_edit' : activeLang === item}"
                 :data="item" :key="item.id">
                 <div class="list-local-item__draggble">
                  <v-icon>mdi-menu</v-icon>
                </div>
                <template v-if="item.language_code === 'en'">
                  <span class="list-local-item__name">
                    <span>{{ item.language_code }}</span>
                  </span>
                </template>
                <template v-else>
                  <span class="list-local-item__name" v-if="activeLang === item">
                    <InputText type="text" v-model="activeNameLang" class="p-inputtext-sm" style="max-width: 90px;" :placeholder="$t('name')" />
                  </span>
                  <span class="list-local-item__name" v-else>
                    <span>{{ item.language_code }}</span>
                  </span>
                </template>
                <div class="list-local-item__actions">
                  <div class="list-local-item__logo" >
                    <p @click="deleteImage" v-if="activeLang === item && (logoPreview !== null || item.logo !== null)">
                      <v-icon color="var(--surface-100)">mdi-close-thick</v-icon>
                    </p>
                    <span @click="triggerInputForFlag" v-if="activeLang === item && logoPreview === null && item.logo === null">
                      <v-icon color="var(--surface-700)">mdi-plus</v-icon>
                    </span>

                    <img v-if="activeLang.id === item.id && (logoPreview !== null || item.logo !== null)" :src="logoPreview"/>
                    <img v-if="activeLang === item && item.logo !== null" :src="item.logo"/>
                    <img v-if="item.logo && activeLang !== item"  :src="item.logo" :alt="item.language_code">
                  </div>
                  <div class="list-local-item__edit" >
                    <Button 
                    @click.stop="updateLang(item)"
                    :disabled="loadingFlag" 
                    :loading="loadingFlag" 
                icon="pi pi-save" class="p-button-sm p-button-success" v-if="activeLang === item" />
                    <Button 
                icon="pi pi-pencil" class="p-button-sm p-button-warning" v-else />
                  </div>
                  <template v-if="item.language_code !== 'en'">
                    <div class="list-local-item__active">
                      <v-switch
                      v-model="item.active"
                      :disabled="activeLang !== item"
                      hide-details
                      color="green accent-4" inset
                      class="mt-0 pt-0 custom-switch "
                      ></v-switch>
                    </div>
                    <div class="list-local-item__delete">
                      <Button icon="pi pi-trash" @click="deleteLang(item)" class="p-button p-component p-button-icon-only p-button-danger p-button-rounded mr-2" />
                    </div>
                  </template>
               
                </div>
              </drag>
              </template>
            </drop-list>
              </ul>
              <li class="list-local__item list-local-item _create" v-if="createNewLocal"
                >
                  <div class="list-local-item__draggble">
                    <v-icon>mdi-menu</v-icon>
                  </div>
                  <span class="list-local-item__name">
                    <!-- <v-text-field v-model="newLocal.name" :label="$t('name')" style="max-width: 215px;" outlined dense hide-details  color="var(--main-flamingo)" /> -->
                    <InputText type="text" v-model="newLocal.language_code" class="p-inputtext-sm" style="max-width: 215px;" :placeholder="$t('name')" />
                  </span>
                  <div class="list-local-item__actions">
                    
                    <div class="list-local-item__logo mr-0" >
                      <p @click="deleteImage" v-if="createNewLocal && logoPreview !== null">
                        <v-icon color="var(--surface-100)">mdi-close-thick</v-icon>
                      </p>
                      <span @click="triggerInputForFlag" v-if="createNewLocal && logoPreview === null">
                        <v-icon color="var(--surface-700)">mdi-plus</v-icon>
                      </span>
                      <img v-if="createNewLocal && logoPreview !== null" :src="logoPreview"/>
                    </div>
                    <div class="list-local-item__edit" >
                    </div>
                    <div class="list-local-item__active">
                      <v-switch
                      v-model="newLocal.active"
                      hide-details
                      color="green accent-4" inset
                      class="mt-0 pt-0 custom-switch "
                      ></v-switch>
                    </div>
                    <div class="list-local-item__delete">
                      <Button icon="pi pi-trash" @click="deleteLang" class="p-button p-component p-button-icon-only p-button-danger p-button-rounded mr-2" />
                    </div>
                 
                  </div>
                </li>
              <div class="list-local__add">
              <Button @click="saveNewLang" :disabled="newLocal.language_code === '' || !newLocal.logo" :loading="loadingFlag" :label="$t('save')"
                icon="pi pi-save" class="p-button-sm p-button-success" v-if="createNewLocal" />
                <Button @click="addNewLang" :disabled="loadingFlag" :loading="loadingFlag" :label="$t('add_new_local')"
                icon="pi pi-save" class="p-button-sm p-button-success" v-else />
                <transition name="scale-transition">
                  <Button v-if="activeLang !== '' && !isEnableReset"
                          @click="resetLang"
                          :loading="loadingFlag"
                          :label="$t('reset')"
                          icon="pi pi-refresh"
                          class="p-button-sm p-button-danger ml-1" />
                </transition>
              </div>
              
            </div>
            
            <div class="local__view view-local">
              <h3 class="view-local__title list-title">
               {{ activeNameLang !== '' ? `${activeNameLang} -` : '' }} {{ $t('json_with_translation') }}
              </h3>
              <div class="view-local__json">
                <Textarea :loading="loadingFlag" :placeholder="$t('json_label')" @input="updateSelectedLocalizationJSON" :readonly="isReadonly" v-model="selectedLocalizationJSONValue" />
                <!-- <v-textarea
                background-color="var(--surface-card)"
                v-model="selectedLocalizationJSON"
                solo
                :readonly="isReadonly"
          :label="this.$t('json_label')"
        ></v-textarea> -->
              </div>
            </div>
          </div>
</template>

<script>
import { Drag, DropList } from "vue-easy-dnd";
import { mapGetters} from 'vuex'
// import draggable from 'vuedraggable';
    export default {
        name: 'GeneralLocalization',
        components:{
          // draggable,
          Drag,
          DropList,
        },
        data() {
            return {
              domain: window.location.hostname.includes("take")
                ? process.env.VUE_APP_API_DOMAIN_FIRST
                : process.env.VUE_APP_API_DOMAIN_SECOND,
                loadingFlag: false,
                activeLang: '',
                activeNameLang: '',
                activeFileLogo: null,
                selectedLocalizationJSONValue: '',
                isReadonly: true,
                createNewLocal: false,
                logoPreview: null,
                selectedLocalization: null,
                hasDraggbleChanges: false,
                draggableLocal: [],
                newLocal:{
                  language_code: '',
                  logo: null,
                  active: false,
                  json: {}
                },
            }
        },
        watch: {
  },
        computed: {
          ...mapGetters({
            localizations: 'localization/getLocalizations'
          }),
          selectedLocalizationJSON: {
    get() {
      if (this.activeLang) {
        return JSON.stringify(this.activeLang.json_data, null, 2);
      } else if (this.createNewLocal) {
        return JSON.stringify(this.newLocal.json, null, 2);
      } else{
        return '';
      }
    },
    set(value) {
    }
  },
  isDomain() {
     if (this.$route.path.includes('agent')) {
      return this.domain;
     } else {
      return 'takemybet.pro'
     }
  },
  isEnableReset(){
    if (this.createNewLocal || this.activeLang !== '') {
    return this.selectedLocalizationJSONValue.trim() === this.selectedLocalizationJSON.trim();
    }
  },
        },
        async mounted(){
          this.getLocals();
          // if (this.localizations.length === 0) {
          // } else{
          //   this.setDraggableOrders();
          // }
        },
        methods:{
          resetOrders(){
            this.draggableLocal = [...this.localizations.items];
            this.hasDraggbleChanges = false;
          },
         async updateOrders(){
          this.loadingFlag = true;
          const items = this.draggableLocal;
          const order = items.map((item, index) => ({
            domain: this.isDomain,
                  language_code: item.language_code,
            order: index * 10, 
          }));
          try {
            await this.$store.dispatch('localization/awaitUpdateOrders', order);
            this.$toast.add({ severity: 'success', summary: this.$t('lang_order'), life: 4000 });
            this.updateLocals();
          } catch (error) {
            this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
          } finally{
            this.hasDraggbleChanges = false;
            this.loadingFlag = false;
          }
          },
          setDraggableOrders(){
            this.draggableLocal = [...this.localizations.items];
                this.selectLang(this.draggableLocal[0]);
          },
          async getLocals(){
            try {
              await this.$store.dispatch('localization/awaitGetLocalizations', this.isDomain);
              this.setDraggableOrders();
            } catch (error) {
              this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 });
            } finally{
              this.loadingFlag = false;
            }
          },
          updateSelectedLocalizationJSON(event) {
          // let jsonString = event;
          // jsonString = jsonString.replace(/'/g, '"');
          this.selectedLocalizationJSONValue = event;
  },
          triggerInputForFlag(){
            document.getElementById('upload-logo-for-local').click();
          },
              uploadFlagForLocal(event) {
              const file = event.target.files[0];
              if (file && file.size <= 15000000) {
                // images
                const imageUrl = URL.createObjectURL(file);
                this.logoPreview = imageUrl;
                if (this.createNewLocal) {
                  this.newLocal.logo = file;
                } else{
                  this.activeFileLogo = file;
                }
              }
            },
            deleteImage(isNewFlag) {
                  const logo = this.logoPreview;
                  URL.revokeObjectURL(logo);
                  this.logoPreview = null;
                  this.$refs.uploadFlagForLocal.value = '';
                  if (this.createNewLocal) {
                  this.newLocal.logo = null;
                } else{
                  this.activeFileLogo = null;
                  this.activeLang.logo ? this.activeLang.logo = null : '';
                }
            },
            //========================================================================================================================================================
            async saveNewLang(){
              this.loadingFlag = true;
              const create = this.newLocal;
              const createData = new FormData();
              createData.append("domain", this.isDomain);
              createData.append("language_code", create.language_code);
              createData.append("json_data", JSON.stringify(this.selectedLocalizationJSONValue));
              createData.append("active", create.active);
              if (!create.logo) {
                createData.append("logo", null);
              } else if (create.logo !== null && typeof create.logo !== 'string') {
                createData.append("logo", create.logo);
              } else if (create.logo.includes("https")) {
                createData.append("logo", create.logo);
              }
              
               const res = await this.$store.dispatch('localization/awaitCreateLocalizations', createData);
               if (res) {
                      this.$toast.add({
                        severity: 'success', summary: this.$t("lang_created"), life: 4000,
                      })
                     this.createNewLocal = false;
                     this.clearNewLocal();
                     this.updateLocals();
               } else{
                     this.$toast.add({
                        severity: 'error', summary: this.$t("just_error"), life: 4000,
                      })
               }
              this.loadingFlag = false;
            },
            async updateLang(local){
              //if (this.isEnableReset) {
              //  this.clearActiveLocal();
              //  return;
              //}
              this.loadingFlag = true;
              const update = local;
              const logo = this.activeFileLogo;
              const updateData = new FormData();
              updateData.append("domain", this.isDomain);
              updateData.append("updated_language_code", this.activeNameLang);
              updateData.append("json_data", JSON.stringify(this.selectedLocalizationJSONValue));
              updateData.append("active", update.active);
              if (!logo) {
                updateData.append("logo", null);
              } else if  (logo !== null && typeof logo !== 'string') {
                updateData.append("logo", logo);
              } else if (logo.includes("https")) {
                updateData.append("logo", logo);
              } 
              try {
                await this.$store.dispatch('localization/awaitUpdateLocalizations', { domain: this.isDomain, language_code: update.language_code, localData: updateData});
                this.$toast.add({
                    severity: 'success', summary: this.$t("lang_updated"), life: 4000,
                  })
                this.isReadonly = true;
                this.clearActiveLocal();
                this.updateLocals();
              } catch (error) {
                this.$toast.add({
                    severity: 'error', summary: this.$t("just_error"), life: 4000,
                  })
                
              } finally{
                this.loadingFlag = false;
              }
            },
            async deleteLang(local){
              const localCode = {
                domain: this.isDomain, 
                language_code: local.language_code
              };
              this.loadingFlag = true;
              if (this.createNewLocal) {
                this.createNewLocal = false;
                this.clearNewLocal();
              } else{
                try {
                 await this.$store.dispatch('localization/deleteLocalization', localCode);
                 this.$toast.add({
                    severity: 'info', summary: this.$t("lang_deleted"), life: 4000,
                  })
                 this.clearActiveLocal();
                 this.updateLocals();
               } catch (error) {
                 this.$toast.add({
                    severity: 'error', summary: this.$t("just_error"), life: 4000,
                  })
               }
                }
                this.loadingFlag = false;
            },
            //========================================================================================================================================================
            resetLang(){
              this.updateSelectedLocalizationJSON(this.selectedLocalizationJSON);
            },
            selectLang(lang){
              if (this.activeLang === lang) {
                return;
              }
              if (this.createNewLocal) {
                this.createNewLocal = false;
              }
              //this.deleteImage();
              this.activeLang = lang;
              this.activeNameLang = lang.language_code;
              this.activeFileLogo = lang.logo;
              this.selectedLocalizationJSONValue = this.selectedLocalizationJSON;
              this.isReadonly = false;
            },
            addNewLang(){
              this.clearActiveLocal();
              this.createNewLocal = true;
              this.isReadonly = false;
              this.selectedLocalizationJSONValue = '{  }';
            },
            clearNewLocal(){
              this.newLocal = {
                  language_code: '',
                  logo: null,
                  active: false,
                  json: {}
                };
              this.deleteImage();
              this.selectedLocalizationJSONValue = '';
              this.selectedLocalizationJSON = '';
              this.isReadonly = true;
            },
            clearActiveLocal(){
              this.activeLang = '';
              this.deleteImage();
              this.isReadonly = true;
              this.selectedLocalizationJSONValue = '';
              this.selectedLocalizationJSON = '';
              this.activeNameLang = '';
            },
            async updateLocals(){
              await this.$store.dispatch('localization/awaitGetLocalizations', this.isDomain);
              this.draggableLocal = [...this.localizations.items];
            },
        },
    }
</script>

<style lang="scss" scoped>
.scale-enter-active, .scale-leave-active {
  transition: scale 0.3s linear;
}
*ul, li{
  padding: 0;
  list-style: none;
}
.list-title {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
}
.local {
  display: flex;
  justify-content: space-between;
  padding: 0 5px!important;
  height: 100%;
  flex-wrap: wrap;
  @media (max-width:992px){
      flex-direction: column;
  }
  &__list {
    @media (min-width:992px){
      flex: 0 1 325px;
  }
    @media (min-width:1150px){
      flex: 0 1 375px;
  }
  }

  &__view {
    @media (min-width:992px){
      flex: 1 1 auto;
  }
    
  }
}
.list{
  display: grid;
  gap: 1px;
}
.list-local {

  &__title {
  }

  &__items {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__item {
  }
  &__add{
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.list-local-item {
  display: flex;
  align-items: center;
  border: 1px solid var(--surface-border);
  padding: 3px;
      &:nth-of-type(odd){
        background-color: var(--surface-100)!important;
    } 
      &:nth-of-type(even){
        background-color: var(--surface-300)!important;
    }
    &._edit {
      background-color: var(--green-100) !important;
        border-color: rgb(1 185 56);
    } 
    &._create{
      background-color: var(--indigo-900) !important;
      border-color: rgba(19, 34, 137, 0.5);
  }
  &__draggble {
    flex: 0 0 50px;
    width: 50px;
    height: 40px;
    display: flex;
    justify-content:center;align-items: center;
    cursor: grab;
    
    i{
      color: var(--text-color-secondary);
      ._edit &{
        color: var(--bluegray-900);
      } 
    }
  }

  &__name {
    flex: 1 1 auto;
    text-align: left;
    color: var(--surface-900);
  }
  &__logo{
    position: relative;
    display: flex;
    justify-content: center;
    margin-right: 8px;
    width: 40px;
    height: 36px;
    flex: 0 0 40px;
    border-radius: 6px;
    span, p{
      display: flex;
      justify-content:center;
      align-items: center;
      cursor: pointer;
    }
    p{
      background-color: var(--red);
      color: var(--surface-900);
      height: 14px;
      padding: 0 3px;
      top: -5px;
      position: absolute;
      border-radius: 50%;
      flex: 0 0 14px;
      width: 14px;
      padding: 0px;
      right: -5px;
      i{
        font-size: 10px;
        color: var(--surface-900);
      }
    }
    span{
    width: 40px;
    height: 36px;
    flex: 0 0 40px;
    border: 2px dashed var(--surface-400);
    background-color: var(--surface-200);
    overflow: hidden;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }

  &__edit {
    .v-btn{
      min-width: 40px;
      ._edit &{
        background-color: var(--green-success)!important;
      }
    }
    
  }

  &__active {
    margin: 0 2px 0 15px;
  }

  &__delete {
    cursor: pointer;
    i{
      color: var(--surface-900);
    }
  }
}


.view-local {
  @media (min-width:992px){
      
    overflow: hidden;
    padding-bottom: 32px;
    //height: 100%;
  }
  &__title {
    text-align: center;
  }

  &__json {
    display: flex;
    height: 100%;
    textarea{
      flex: 1 1 auto;
      height: 100%;
      @media (max-width:992px){
      
        height: 1000px;
      }
    }
    @media (min-width:992px){
      margin-left: 10px;
  }

  }
}

</style>
  